import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Tasacion joyas",
  "image": "../../images/tasacion_joyas.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`¿Qué es la tasación de joyas?`}</h2>
    <p>{`La tasación de joyas es indicar el valor aproximado que tienen en el mercado estos bienes preciados que se están analizando.`}</p>
    <h2>{`¿Para qué tasar las joyas?`}</h2>
    <p>{`Las joyas se tasan principalmente para su venta. Uno de los motivos más frecuentes es heredar un conjunto de joyas y evaluar su precio con una tasación, tanto para la posible venta, como para realizar la división de los bienes de la propia herencia e incluso para incorporar en el contenido de un seguro de hogar.
Otro motivo que existe es querer información sobre el material usado para valorar una posible sustitución de algún elemento dañado. También simplemente para la estimación de tu propia colección o patrimonio y conocer más sobre los materiales y técnica utilizadas. `}</p>
    <h2>{`¿Cómo tasar joyas?`}</h2>
    <p>{`Habitualmente las joyas las tasa un profesional que está capacitado para realizar un informe donde se justifica el valor de la tasación. Las joyas que se tasan están compuestas por metales y piedras preciosas de alta cotización y se refleja en el documento la composición y características de sus componentes. Si la joya contiene diamantes y el tasador lo considera oportuno, es necesario realizar un estudio gemológico para otorgar el certificado de diamantes y que pueda obtener un precio más justo en función del valor del diamante analizado.
El informe queda por lo tanto definido con los siguientes puntos:`}</p>
    <ol>
      <li parentName="ol">{`Análisis de los metales y materiales.`}</li>
      <li parentName="ol">{`Fotografías de la joya.`}</li>
      <li parentName="ol">{`Análisis gemológico de las piedras preciosas y gemas.`}</li>
      <li parentName="ol">{`Descripción detallada de la joya y sus componentes`}</li>
      <li parentName="ol">{`Valoración final.`}</li>
    </ol>
    <h3>{`Los criterios principales por lo que se determina el valor de una joya son:`}</h3>
    <ol>
      <li parentName="ol">{`Valor de liquidación`}</li>
      <li parentName="ol">{`Valor de reposición`}</li>
      <li parentName="ol">{`Valor del mercado secundario:`}</li>
      <li parentName="ol">{`Materias primas`}</li>
      <li parentName="ol">{`Metales`}</li>
      <li parentName="ol">{`Gemas`}</li>
      <li parentName="ol">{`Mano de obra realizada`}</li>
      <li parentName="ol">{`Historia de la joya`}</li>
      <li parentName="ol">{`Marca`}</li>
    </ol>
    <h2>{`¿Quién puede tasar joyas?`}</h2>
    <p>{`Como hemos comentado anteriormente la tasación de joyas debería realizarla un profesional, pero actualmente existen algunas empresas menos especializadas en joyas que se dedican al oro que también pueden realizar una tasación.
Nosotros recomendamos en este caso siempre acudir a profesionales colegiados con conocimientos específicos para tasar de una forma más detallada y precisa.`}</p>
    <h2>{`¿Cómo tasar una joya de forma online?`}</h2>
    <p>{`Existen tablas y algunas herramientas online para estimar el precio de una joya pero no son sencillas de utilizar y no van a ser precisas, por lo que podemos estar comiendo probablemente errores que desvirtuarán la tasación real.
Si recomendamos ir a través organismos oficiales como el Instituto Gemológico Español (IGE) que dispone de un servicio de tasación y que son realizadas de forma profesional ya que no tienen relación con la compraventa y conforme a la normativa vigente.
Ofrecen tarifas de tasación según la cantidad y tipo de en el siguiente enlace:`}</p>
    <p><a parentName="p" {...{
        "href": "https://ige.org/"
      }}>{`Instituto Gemológico Español - IGE`}</a></p>
    <p>{`También se puede buscar a un experto en la `}<a parentName="p" {...{
        "href": "https://www.tasadoresjoyas.com/"
      }}>{`asociación española de tasadores de Alhajas`}</a></p>
    <h2>{`¿Cómo saber que un diamante es auténtico?`}</h2>
    <p>{`Los 3 pasos para reconocer si un diamante es verdadero:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Reflejo`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`El brillo del diamante tiene tonos grisáceos y es el más potente junto al de otra piedra preciosa llamada moissanita. Entre ellas se pueden confundir, pero se diferencian si se utiliza una luz potente ya que en la moissanita aparecerá un arcoíris que el diamante no contiene. Es el efecto de la doble refracción.`}</li>
    </ul>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><strong parentName="li">{`Lectura`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Otro truco es tener un libro o página con texto y dejando el diamante encima intentar leer lo que está escrito. Si puedes leer, el diamante es falso.`}</li>
    </ul>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`Calor`}</strong></li>
    </ol>
    <ul>
      <li parentName="ul">{`Otra técnica es calentar el diamante y meterlo rápidamente en un vaso de agua fría, no debe romperse, ya que es uno de los materiales más duros del mundo. Por lo que si tras esta prueba no ha sufrido ningún daño puedes empezar a pensar que dispones de un diamante en tus manos.`}</li>
    </ul>
    <h2>{`¿Qué son los quilates de un diamante?`}</h2>
    <p>{`El peso de un diamante y otras gemas talladas se expresa en quilates: 1 quilate equivale a 0,20 gramos. Este concepto de quilate no tiene nada que ver con el de quilate que se utiliza en el oro. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      